<template>
	<div class="min-h-screen flex flex-col bg-white">
		<LazyUInfoBanner
			v-if="$hasFeature(CONFIG_BANNER_HIRE)"
			:config="$feature.getFeatureValue(CONFIG_BANNER_HIRE)"
		/>

		<div class="flex flex-col lg:flex-row flex-1">
			<div class="relative z-0 hidden lg:flex flex-col justify-center w-full max-w-2xl px-10 bg-neutral-700 text-white">
				<BackgroundLogin class="absolute right-0 top-0 object-fit -z-10" />

				<p class="text-left text-primary-400 text-sm font-semibold">
					{{ $t('TALENTLY PARA EMPRESAS') }}
				</p>
				<p
					class="font-bold text-4.6xl text-white"
					v-html="title"
				/>
			</div>

			<div class="w-full relative flex flex-col justify-between">
				<div class="block lg:hidden w-full p-4 bg-neutral-700 text-primary-400 font-bold text-xs">
					{{ $t('TALENTLY PARA EMPRESAS') }}
				</div>

				<span class="p-4 flex justify-end">
					<LanguageSwitching />
				</span>

				<slot />

				<span></span>
			</div>
		</div>
	</div>
</template>

<script setup>
import BackgroundLogin from '~/components/BackgroundLogin.vue'
import LanguageSwitching from '~/components/LanguageSwitching.vue'
import { CONFIG_BANNER_HIRE } from '~/config/featureFlags'

const { t: $t } = useI18n()

const title = computed(() => {
	return $t(
		'Encuentra los <span class="text-primary-400">mejores perfiles tech</span> que tu empresa necesita para crecer.',
	)
})
</script>
