<template>
	<div class="h-full w-full">
		<svg
			width="100%"
			height="100%"
			viewBox="0 0 535 1000"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				d="M61.5381 1077H-24.321C-39.0559 1077 -51 1064.17 -51 1048.34V984.659C-51 968.827 -39.0559 956 -24.321 956H40.3209C55.0559 956 67 968.827 67 984.659V1071.15C67 1074.38 64.5392 1077 61.5381 1077Z"
				fill="#FF4343"
			/>
			<path
				d="M73.0829 956H105.826C111.445 956 116 951.23 116 945.342V921.658C116 915.77 111.445 911 105.826 911H81.1742C75.5549 911 71 915.77 71 921.658V953.825C71 955.027 71.9385 956 73.0829 956Z"
				fill="#866CCE"
			/>
			<path
				d="M484.611 80.5029H462.783C459.037 80.5029 456 77.2031 456 73.1303V56.7476C456 52.6748 459.037 49.375 462.783 49.375H479.217C482.963 49.375 486 52.6748 486 56.7476V78.9984C486 79.8296 485.374 80.5029 484.611 80.5029Z"
				fill="#FFCD00"
			/>
			<path
				d="M577.88 65.476H513.122C502.009 65.476 493 55.918 493 44.1209V-3.33235C493 -15.1294 502.009 -24.6875 513.122 -24.6875H561.878C572.991 -24.6875 582 -15.1294 582 -3.33235V61.1183C582 63.5259 580.144 65.476 577.88 65.476Z"
				fill="#4CDBD5"
			/>
		</svg>
	</div>
</template>
